/* App.svelte generated by Svelte v3.20.1 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	detach,
	element,
	globals,
	init,
	insert,
	noop,
	safe_not_equal
} from "svelte/internal";

const { document: document_1 } = globals;
import "./font.css";
import { Terminal } from "xterm";
import { onMount } from "svelte";
import { FitAddon } from "xterm-addon-fit";
import { WebLinksAddon } from "xterm-addon-web-links";
import decodeUTF8 from "./decoder";
import "xterm/css/xterm.css";

function add_css() {
	var style = element("style");
	style.id = "svelte-1bn3pmb-style";
	style.textContent = "body{margin:0;background-color:black}main.svelte-1bn3pmb{background-color:black;margin:0;height:100vh}#xterm.svelte-1bn3pmb{width:100%;height:100%}";
	append(document_1.head, style);
}

function create_fragment(ctx) {
	let main;
	let div;

	return {
		c() {
			main = element("main");
			div = element("div");
			attr(div, "id", "xterm");
			attr(div, "class", "svelte-1bn3pmb");
			attr(main, "class", "svelte-1bn3pmb");
		},
		m(target, anchor) {
			insert(target, main, anchor);
			append(main, div);
			/*div_binding*/ ctx[1](div);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(main);
			/*div_binding*/ ctx[1](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let terminalDiv;

	onMount(() => {
		let term = null;

		// TODO: Introduce dev/prod switching based on ENV
		var websocket = new WebSocket("wss://progapanda.org/term");

		websocket.binaryType = "arraybuffer"; // ????

		function binaryString(buf) {
			return decodeUTF8(String.fromCharCode.apply(null, new Uint8Array(buf)));
		}

		websocket.onopen = function (evt) {
			term = new Terminal({
					cursorBlink: true,
					screenKeys: true,
					useStyle: true
				});

			if (term) {
				term.setOption("logLevel", "debug");
				const fitAddon = new FitAddon();
				const linksAddon = new WebLinksAddon();
				term.loadAddon(fitAddon);
				term.loadAddon(linksAddon);

				term.onData(function (data) {
					websocket.send(new TextEncoder().encode("\u0000" + data));
				});

				term.onResize(function (evt) {
					console.log(term.rows, "x", term.cols);
					websocket.send(new TextEncoder().encode("\u0001" + JSON.stringify({ cols: evt.cols, rows: evt.rows })));
				});

				websocket.onmessage = function (evt) {
					term.write(binaryString(evt.data));
					fitAddon.fit();
				};

				websocket.onclose = function (evt) {
					if (term) {
						term.write("Session terminated");
					}
				};

				websocket.onerror = function (evt) {
					if (typeof console.log == "function") {
						console.log(evt);
					}
				};

				term.onTitleChange(function (title) {
					document.title = title;
				});

				term.setOption("fontFamily", "VT323-Regular");
				term.setOption("fontSize", 24);
				term.open(terminalDiv);
				term.focus();

				window.addEventListener("resize", () => {
					fitAddon.fit();
				});
			}
		};
	});

	function div_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			$$invalidate(0, terminalDiv = $$value);
		});
	}

	return [terminalDiv, div_binding];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		if (!document_1.getElementById("svelte-1bn3pmb-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;